import { EventEmitter, Input, OnDestroy, Output } from "@angular/core";
import { YumealzApiService } from "../../../services/yumealz-api-service/yumealz-api.service";
import { Component, OnInit } from "@angular/core";
import { IDeliveryWindow } from "src/app/shared/models/classes.model";
import { Subscription } from "rxjs";

@Component({
	selector: "app-select-delivery-window",
	templateUrl: "./select-delivery-window.component.html",
	styleUrls: ["./select-delivery-window.component.scss"],
})
export class SelectDeliveryWindowComponent implements OnInit, OnDestroy {
	@Input() supportMultiSelect: boolean = false;
	@Input() onlyShowActive: boolean = false;
	@Input() preSelectedSingleValue: number | undefined;
	@Input() appId?: number | null;
	@Input() cityId?: number | null;
	@Input() locationId?: number | null; // Used to get restricted dws based on customer's location
	@Input() restaurantId?: number | null; // Used to get restricted dws based on selected restaurant
	@Input() saas: boolean = true;
	@Input() disabled: boolean = false;
	@Input() includeAllOption: boolean = false;
	@Input() includeNullOption: boolean = false;

	@Output() isDeliveryWindowOptionsEmpty: EventEmitter<boolean> =
		new EventEmitter<boolean>();

	@Output() isLoadingDeliveryWindowOptions: EventEmitter<boolean> =
		new EventEmitter<boolean>();
	@Output() singleSelectEvent: EventEmitter<number> =
		new EventEmitter<number>();
	@Output() singleSelectDeliveryWindow: EventEmitter<IDeliveryWindow> =
		new EventEmitter<IDeliveryWindow>();
	@Output() multiSelectEvent: EventEmitter<number[]> = new EventEmitter<
		number[]
	>();
	@Output() multiSelectDeliveryWindows: EventEmitter<IDeliveryWindow[]> =
		new EventEmitter<IDeliveryWindow[]>();

	selectedValue: number = -1;
	isLoading: boolean = false;
	deliveryWindowsOptions: IDeliveryWindow[] = [];
	subscriptions: Subscription[] = [];

	get isRestricted() {
		// all of these values must not be null to get Restricted delivery windows
		return (
			![this.cityId, this.locationId, this.restaurantId].includes(undefined) &&
			![this.cityId, this.locationId, this.restaurantId].includes(null)
		);
	}

	constructor(private yumealzApiService: YumealzApiService) {}

	ngOnInit(): void {
		if (this.isRestricted) this.getRestrictedDeliveryWindows();
		else this.getDeliveryWindows();
	}

	getDeliveryWindows() {
		this.isLoadingDeliveryWindowOptions.emit(true);
		this.isLoading = true;
		this.subscriptions.push(
			this.yumealzApiService
				.getPaginatedListOfDeliveryWindows({
					page_size: 1000,
					page: 1,
					app_id: this.appId,
					city_id: this.cityId,
					saas: this.saas,
					is_active: this.onlyShowActive ? this.onlyShowActive : undefined,
				})
				.subscribe((result) => {
					this.deliveryWindowsOptions = result.results;
					this.selectedValue =
						this.preSelectedSingleValue === undefined
							? -1
							: this.preSelectedSingleValue;
					this.isLoading = false;
					this.isLoadingDeliveryWindowOptions.emit(false);
				})
		);
	}

	getRestrictedDeliveryWindows() {
		this.isLoadingDeliveryWindowOptions.emit(true);
		this.isLoading = true;
		this.subscriptions.push(
			this.yumealzApiService
				.getRestrictedDeliveryWindows({
					app_id: this.appId,
					city_id: this.cityId!,
					primary_location_id: this.locationId!,
					restaurant_id: this.restaurantId!,
				})
				.subscribe((result) => {
					this.deliveryWindowsOptions = result;
					this.selectedValue =
						this.preSelectedSingleValue === undefined
							? -1
							: this.preSelectedSingleValue;
					this.isLoading = false;
					this.isLoadingDeliveryWindowOptions.emit(false);
				})
		);
	}

	outputSelectEvent() {
		if (this.supportMultiSelect) {
			// if (this.selectedValue !== undefined)
			// 	this.multiSelectEvent.emit([this.selectedValue]);
		} else {
			this.singleSelectEvent.emit(this.selectedValue);
			this.singleSelectDeliveryWindow.emit(
				this.deliveryWindowsOptions.find((w) => w.id === this.selectedValue)
			);
		}
	}
	get filteredDeliveryWindowsOptions() {
		let filteredDeliveryWindowsOptions: IDeliveryWindow[] = [];
		if (this.onlyShowActive) {
			filteredDeliveryWindowsOptions = this.deliveryWindowsOptions.filter(
				(deliveryWindow) => deliveryWindow.is_active
			);
		} else {
			filteredDeliveryWindowsOptions = this.deliveryWindowsOptions;
		}
		let sortedDeliveryWindowsOptions = filteredDeliveryWindowsOptions.sort(
			(a, b) => {
				const aTime = a.from_time.split(":")[0];
				const bTime = b.from_time.split(":")[0];
				return parseInt(aTime) - parseInt(bTime);
			}
		);
		this.isDeliveryWindowOptionsEmpty.emit(
			sortedDeliveryWindowsOptions.length > 0 ? true : false
		);
		return sortedDeliveryWindowsOptions;
	}
	ngOnDestroy(): void {
		this.subscriptions.forEach((subscription) => subscription.unsubscribe());
	}
}
