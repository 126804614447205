<!-- <div *ngIf="isSideBarCollapsed" (click)="isSideBarCollapsed = false">
	<mat-icon>menu</mat-icon>
</div> -->

<div class="side-bar-container">
	<div
		class="side-bar-blur-mobile"
		[ngClass]="sideBarMobileBlurClassList"
		(click)="onSideBarClick()"
	></div>
	<div class="side-bar" [classList]="sideBarClassList">
		<div class="environment-container" *ngIf="currentEnv">
			<p>{{ currentEnv }}</p>
		</div>
		<div class="logo-name-wrapper">
			<div class="logo-name">
				<img
					src="../../../../../assets/Techrar icon.png"
					class="logo"
					alt="logo app"
					srcset=""
				/>
				<span class="logo-name__name"
					>{{ "dashboard" | translate }} {{ appVersion.version }}
				</span>
			</div>
			<button
				class="logo-name__button"
				[class.reversed]="lang == 'ar'"
				(click)="onSideBarClick()"
			>
				<i [classList]="arrowClassList" id="logo-name__icon"></i>
			</button>
			<!-- <i class='bx bx-arrow-from-left'></i> -->
		</div>

		<!-- could be used for new actions -->
		<!-- <div class="message">
			<i class="message-icon bx bx-message-square-edit"></i>
			<span class="message-text">New Mesage</span>
			<span class="tooltip">New Mesage</span>
		</div> -->

		<ul class="features-list" *ngIf="activeRoute != '/onboarding'">
			<ng-container *ngFor="let feature of featuresList">
				<a
					[routerLinkActive]="['active']"
					*ngIf="appService.canView(feature.permissionCode)"
					[class]="getFeatureClasses(feature)"
					[routerLink]="[feature.route]"
					[matTooltip]="feature.text | translate"
					[matTooltipPosition]="isEnglish() ? 'right' : 'left'"
					[matTooltipDisabled]="!isSideBarCollapsed"
					class="features-item"
					[class.centered-feature]="isSideBarCollapsed"
					(click)="onSideBarLinkClick()"
				>
					<i [class]="getFeaturesCollapsedClasses(feature)"
						><span class="status"></span
					></i>
					<span class="features-item-text" *ngIf="!isSideBarCollapsed">{{
						feature.text | translate
					}}</span>
					<span *ngIf="feature.numberOfUpdates" class="inbox-number"></span>

					<!-- tooltip is not working and replaced with mat tool tip -->
					<!-- <span class="tooltip features-item-text-tooltip">{{
						feature.text
					}}</span> -->
				</a>
			</ng-container>

			<ng-container *ngFor="let featureFolder of featuresFoldersList">
				<div class="features-folder-container">
					<li
						(click)="toggleFolder(featureFolder)"
						*ngIf="appService.canView(featureFolder.permissionCode)"
						[class]="getFeatureClasses(featureFolder)"
						class="features-folder"
						[class.centered-feature-folder]="isSideBarCollapsed"
						[matTooltip]="featureFolder.text | translate"
						[matTooltipPosition]="isEnglish() ? 'right' : 'left'"
						[matTooltipDisabled]="!isSideBarCollapsed"
					>
						<i [class]="getFeaturesCollapsedClasses(featureFolder)"
							><span class="status"></span
						></i>
						<span class="features-item-text" *ngIf="!isSideBarCollapsed">{{
							featureFolder.text | translate
						}}</span>
						<div class="spacer" *ngIf="!isSideBarCollapsed"></div>
						<span *ngIf="!isSideBarCollapsed">
							<i
								*ngIf="!isFolderCollapsed(featureFolder)"
								class="bx bx-chevron-up"
							></i>
							<!-- arrow up if closed -->
							<i
								*ngIf="isFolderCollapsed(featureFolder)"
								class="bx bx-chevron-down"
							></i>
						</span>
						<span *ngIf="featureFolder.numberOfUpdates" class="inbox-number">{{
							featureFolder.numberOfUpdates
						}}</span>
						<span class="tooltip">{{ featureFolder.text }}</span>
					</li>
					<div
						*ngIf="!isFolderCollapsed(featureFolder)"
						class="folder-features"
					>
						<ng-container *ngFor="let feature of featureFolder.features">
							<a
								class="folder-feature"
								[routerLinkActive]="['active']"
								[class.collapsed-sidebar-folder-feature]="isSideBarCollapsed"
								*ngIf="appService.canView(feature.permissionCode)"
								[routerLink]="
									feature.fullRoute
										? feature.fullRoute
										: [featureFolder.route, feature.route]
								"
								(click)="onSideBarLinkClick()"
							>
								<i [class]="getFeaturesCollapsedClasses(feature)"
									><span class="status"></span
								></i>
								<div class="folder-feature-text">
									{{ (isSideBarCollapsed ? "" : feature.text) | translate }}
								</div>
							</a>
						</ng-container>
					</div>
				</div>
			</ng-container>
			<ng-container *ngFor="let feature of belowFeaturesList">
				<a
					[routerLinkActive]="['active']"
					*ngIf="appService.canView(feature.permissionCode)"
					[class]="getFeatureClasses(feature)"
					[routerLink]="[feature.route]"
					[matTooltip]="feature.text | translate"
					[matTooltipPosition]="isEnglish() ? 'right' : 'left'"
					[matTooltipDisabled]="!isSideBarCollapsed"
					class="features-item"
					[class.centered-feature]="isSideBarCollapsed"
					(click)="onSideBarLinkClick()"
				>
					<i [class]="getFeaturesCollapsedClasses(feature)"
						><span class="status"></span
					></i>
					<span class="features-item-text" *ngIf="!isSideBarCollapsed">{{
						feature.text | translate
					}}</span>
					<span *ngIf="feature.numberOfUpdates" class="inbox-number"></span>

					<!-- tooltip is not working and replaced with mat tool tip -->
					<!-- <span class="tooltip features-item-text-tooltip">{{
						feature.text
					}}</span> -->
				</a>
			</ng-container>
		</ul>
		<div class="side-bar-bottom-nav">
			<a
				class="bx bxs-news updates"
				href="https://techrar.co/index.php/updates/"
				target="_blank"
				class="features-item"
				[class.centered-feature]="isSideBarCollapsed"
				style="margin-top: 1rem"
				(click)="onSideBarLinkClick()"
			>
				<i class="bx bxs-news updates features-item-icon"
					><span class="status"></span
				></i>
				<span class="features-item-text" *ngIf="!isSideBarCollapsed">{{
					"updates" | translate
				}}</span>
				<span *ngIf="true" class="inbox-number"></span>

				<!-- tooltip is not working and replaced with mat tool tip -->
				<!-- <span class="tooltip features-item-text-tooltip">{{
						feature.text
					}}</span> -->
			</a>

			<div
				*ngIf="isLoggedIn"
				class="logout-btn"
				[class.centered-logout-btn]="isSideBarCollapsed"
				(click)="logOut()"
			>
				<div class="bx bxs-log-out features-item-icon"></div>
				<span class="logout-text" *ngIf="!isSideBarCollapsed">{{
					"logout" | translate
				}}</span>
			</div>
		</div>
		<!-- <ul class="category-list">
		<div class="category-header">Message categories</div>
		<li class="category-item">
			<span
				class="category-item-status"
				style="background-color: #79d861"
			></span
			><span class="category-item-text">My works</span
			><span class="category-item-number">9</span>
			<span class="tooltip">My works</span>
		</li>
		<li class="category-item">
			<span
				class="category-item-status"
				style="background-color: #c43c5d"
			></span
			><span class="category-item-text">Accountant</span
			><span class="category-item-number">43</span>
			<span class="tooltip">Accountant</span>
		</li>
		<li class="category-item">
			<span
				class="category-item-status"
				style="background-color: #ff5050"
			></span
			><span class="category-item-text">Works</span
			><span class="category-item-number">78</span>
			<span class="tooltip">Works</span>
		</li>
		<li class="category-item">
			<span
				class="category-item-status"
				style="background-color: #42ffdd"
			></span
			><span class="category-item-text">Marketing</span
			><span class="category-item-number">253</span>
			<span class="tooltip">Marketing</span>
		</li>
	</ul> -->

		<!-- <ul class="chat-list">
		<div class="chat-header">recent chats</div>
		<button class="chat-new-btn">
			<i class="bx bxs-plus-circle chat-icon"></i>
			<span class="chat-new-btn-text">Start New Chat</span>
			<span class="tooltip">New Chat</span>
		</button>

		<li class="chat-item">
			<span class="chat-item-avatar-wrapper has-message">
				<img
					src="../../../../../assets/yumealz_tongue.png"
					alt="avatar"
					class="chat-item-avatar"
				/>
			</span>
			<span class="chat-item-name">Steve Rogers</span>
			<span class="chat-item-number">53</span>
		</li>
		<li class="chat-item">
			<span class="chat-item-avatar-wrapper">
				<img
					src="../../../../../assets/yumealz_tongue.png"
					alt="avatar"
					class="chat-item-avatar"
				/>
			</span>
			<span class="chat-item-name">Tony Stark</span
			><span class="chat-item-status" style="background-color: #79d861"></span>
		</li>
	</ul> -->
	</div>

	<div
		[class.loggedOut]="!isLoggedIn"
		class="sidebar-content"
		[class.collapsed-sidebar-app-margin]="isSideBarCollapsed"
	>
		<div class="navbar" [class.loggedOut]="!isLoggedIn">
			<div class="start-side-container">
				<div class="nav-name-menu-icon-container">
					<button class="stack-menu__button" (click)="onSideBarClick()">
						<i class="bx bx-menu"></i>
					</button>

					<div class="name-container">
						<h6 class="greeting">{{ "welcome" | translate }}</h6>
						<h4 class="user-name">{{ getUserName() }}</h4>
						<p class="greeting">{{ dashboardUserProfile?.username }}</p>
					</div>
				</div>
				<div
					class="environment-container"
					[class.loggedOut]="!isLoggedIn"
					*ngIf="currentEnv && shouldShowTestOnTopBar"
				>
					<p>{{ currentEnv }}</p>
				</div>
			</div>

			<div class="org-container">
				<div *ngIf="dashboardUserProfile" class="org">
					<img
						[src]="currentOrganization?.logo"
						alt=""
						style="width: 28px; height: 28px; border-radius: 2px"
					/>
					<p>
						{{
							(lang === "en"
								? currentOrganization?.name_en ?? "-"
								: currentOrganization?.name_ar ?? "-"
							).toUpperCase()
						}}
					</p>
				</div>
				<a
					[routerLink]="['organizations/', currentOrganization?.id]"
					class="view-icon"
				>
					<mat-icon>settings</mat-icon>
				</a>
				<div
					class="dummy-container"
					*ngIf="otherOrganizations.length > 0"
				></div>
				<div class="organizations" *ngIf="otherOrganizations.length > 0">
					<div
						class="org-option"
						*ngFor="let organization of otherOrganizations"
						(click)="changeOrganization(organization.id)"
					>
						<img
							[src]="organization?.logo"
							alt=""
							style="width: 28px; height: 28px; border-radius: 2px"
						/>
						<div class="spacer"></div>
						<p>
							{{
								(lang === "en"
									? organization?.name_en ?? "-"
									: organization?.name_ar ?? "-"
								).toUpperCase()
							}}
						</p>
					</div>
				</div>
				<div class="spacer"></div>
				<div class="divider"></div>
				<div class="spacer"></div>
				<div class="wifi-container" [class.loggedOut]="!isLoggedIn">
					<i
						*ngIf="isOnline"
						[matTooltip]="'you-are-online' | translate"
						class="bx bx-wifi online connectivity-status"
					></i>
					<i
						*ngIf="!isOnline"
						[matTooltip]="'you-are-offline' | translate"
						class="bx bx-wifi-off offline connectivity-status"
					></i>
				</div>
				<div
					class="language-button"
					(click)="changeLanguage()"
					[matTooltip]="changeLanguageMessage"
				>
					<mat-icon>language</mat-icon>
				</div>
			</div>
		</div>
		<div class="my-app-container">
			<!-- <app-under-maintenance-page></app-under-maintenance-page> -->
			<router-outlet></router-outlet>
		</div>
	</div>
</div>
