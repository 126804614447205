import { AppService } from "src/app/shared/services/app-service/app-service";
import { Injectable, OnDestroy } from "@angular/core";
import {
	ActivatedRouteSnapshot,
	CanActivate,
	Router,
	RouterStateSnapshot,
	UrlTree,
} from "@angular/router";
import { map, Observable, Subscription, tap } from "rxjs";

@Injectable({
	providedIn: "root",
})
export class AccessGuardGuard implements CanActivate, OnDestroy {
	constructor(private appService: AppService, private router: Router) {}

	subscriptions: Subscription[] = [];
	readonly urlPermissions = {
		"/bulk-update-permission-groups": "can_bulk_update_organization_profiles",
		"/reports/grouped-sub-orders-financial":
			"can_view_sub_order_financial_report",
		"/reports/sub-orders-financial": "can_view_sub_order_financial_report",
		"/orders/all": "can_list_orders",
		"/orders/**": "can_retrieve_order",
		"/restaurants/all": "can_list_restaurants",
		"/restaurants/**": "can_retrieve_restaurant",

		"/restaurants/**/inventory": "can_update_product",
		"/restaurants/**/app-config": "can_retrieve_app_config",
		"/restaurants/new": "can_create_restaurant",
		"/restaurants/users": "can_list_restaurant_users",
		"/restaurants/add-user": "can_create_restaurant_user",
		"/restaurants/edit-user/**": "can_update_restaurant_user",
		"/complaints/all": "can_list_complaints",
		"/complaints/**": "can_retrieve_complaint",
		"/subscriptions": "can_list_subscriptions",
		"/subscriptions/all": "can_list_subscriptions",
		"/subscriptions/**": "can_retrieve_subscription",
		"/subscriptions/create": "can_place_order",
		// "/subscriptions/create": "can_create_subscription",
		"/corporates/all": "can_list_corporates",
		"/corporates/corporate-locations/all": "can_list_corporate_locations",
		"/corporates/corporate-locations/**": "can_retrieve_corporate_location",
		"/corporates/**": "can_retrieve_corporate",
		"/on-demand-orders/all": "can_list_orders",
		"/on-demand-orders/map": "can_list_orders",
		"/on-demand-orders/**": "can_list_orders",
		"/invoices/all": "can_list_sub_order_invoices",

		"/plans-v2/all": "can_list_plans",
		"/menus/all": "can_list_menus",
		"/menus/**": "can_retrieve_menu",
		"/menus/plans/all": "can_list_menued_plans",
		"/menus/plans/create": "can_create_menued_plan",
		"/menus/plans/**": "can_retrieve_menued_plan",
		"/menus/create": "can_create_plan",
		"/menus/update": "can_update_plan",
		"/menus/prices": "can_list_plan_versions",

		"/plans-v2/**": "can_retrieve_plan",
		"/plans-v2/create-plan": "can_create_plan",
		"/plans-v2/plan-tags": "can_list_plan_tags",
		"/plans-v2/prices": "can_list_plan_versions",

		"/customers/all": "can_list_customers",
		"/customers/reports": "can_list_customers",
		"/customers/**": "can_retrieve_customer",

		"/sub-orders/all": "can_list_sub_orders",
		"/sub-orders/**": "can_retrieve_sub_order",
		"/suborders-v2": "can_list_sub_orders",

		"/delivery/sub-orders-map": "can_view_sub_orders_map",
		"/delivery/on-demand-orders-map": "can_list_orders",
		"/delivery/captains": "can_list_captains",
		"/delivery/captains/**": "can_retrieve_captain",
		"/delivery/add-user": "can_create_captain",
		"/delivery/edit-user/**": "can_update_captain",
		"/delivery/district-groups": "can_create_district_group",
		"/delivery/open-suborders-summary": "can_list_sub_orders",
		"/delivery/zones": "can_list_zones",
		"/delivery/delivery-windows": "can_list_delivery_windows",
		"/reports/store-billOfQty": "can_view_boq_report",
		"/reports/store-packaging": "can_view_on_demand_packaging_report",
		"/reports/sub-billOfQty": "can_view_boq_report",
		"/reports/sub-packaging": "can_view_sub_packaging_report",
		"/reports/delivery-report": "can_list_sub_orders",
		"/reports/summary-finance-report": "can_view_finance_report",
		"/reports/detailed-finance-report": "can_view_detailed_finance_report",
		"/reports/financial": "can_view_sub_order_financial_report",
		"/reports/market-place-financial": "can_retrieve_mp_restaurant_invoice",
		"/reports/sub-delivery": "can_view_sub_delivery_report",
		"/reports/subscriptions-notes": "can_view_sub_packaging_report",
		"/reports/subscriptions": "can_list_subscriptions",
		"/reports/wallets/all": "can_list_wallets",

		"/vouchers/all": "can_list_vouchers",
		"/vouchers/**": "can_retrieve_voucher",
		"/vouchers/map-tool": "can_list_vouchers",
		"/dashboard": "can_view_general_statistics",
		"/organizations/all": "can_list_organizations",
		"/organizations/**": "can_retrieve_organization",
		"/organizations/**/profiles/**": "can_retrieve_org_user_profile",
		"/campaigns": "can_list_notifications",
		"/campaigns/create": "can_create_notification",
		"/campaigns/edit": "can_create_notification",
		"/products": "can_list_products",
		"/products/**": "can_list_products",
		"/inventory": "can_list_products",
		"/inventory/**": "can_list_products",
		"/my-fatoorah": "can_view_supplier_dashboard",
		"/my-fatoorah/**/deposited-invoices": "can_view_supplier_dashboard",
		"/my-fatoorah/**": "can_view_supplier_dashboard",
		"/sizes": "can_list_menus",
		"/sizes/**": "can_list_menus",
		"/branches": "can_list_restaurant_branches",
		"/branches/**": "can_list_restaurant_branches",
		"/app-settings": "can_retrieve_app_config",
		"/app-settings/**": "can_retrieve_app_config",
		"/analytics": "can_view_quicksight_report",
		"/abandoned-carts/all": "can_list_orders",
		"/techrar-apps": "can_install_apps",
		"/techrar-apps/**": "can_install_apps",
		"/otp": "can_list_otps",
	};

	canActivate(
		route: ActivatedRouteSnapshot,
		state: RouterStateSnapshot
	):
		| Observable<boolean | UrlTree>
		| Promise<boolean | UrlTree>
		| boolean
		| UrlTree {
		const url = this.getProcessedUrl(state.url);

		const permissionRequiredForUrl =
			this.urlPermissions[url as keyof typeof this.urlPermissions];

		return this.appService
			.getDashboardUserProfile()
			.pipe(
				map((user) => {
					if (!user) return false;

					return user.permissions.includes(permissionRequiredForUrl);
				})
			)
			.pipe(
				tap((hasPermission) => {
					if (!hasPermission) {
						let noPermissionMessage = this.appService.translate(
							"you-dont-have-the-required-permission-to-access-this-page-you-need-this-permission"
						);

						noPermissionMessage += `'${permissionRequiredForUrl}'`;
						this.appService.notifyFailure(noPermissionMessage);
						this.navigateToHomePage();
					}
				})
			);
	}

	getProcessedUrl(url: string): string {
		const urlParts = url.split("/").filter((part) => part !== "");

		for (let i = 0; i < urlParts.length; i++) {
			if (!isNaN(+urlParts[i])) urlParts[i] = "**";
			if (urlParts[i].includes("?")) {
				const questionMarkIndex = urlParts[i].indexOf("?");
				urlParts[i] = urlParts[i].substring(0, questionMarkIndex);
			}
		}
		return "/" + urlParts.join("/");
	}

	navigateToHomePage() {
		this.subscriptions.push(
			this.appService.getDashboardUserProfile().subscribe((user) => {
				if (!user) return;

				const needsOnboardingStoredValue =
					localStorage.getItem("needs_onboarding");
				if (needsOnboardingStoredValue !== "false")
					this.router.navigateByUrl("/onboarding");
				if (user.permissions.includes("can_view_general_statistics")) {
					this.router.navigateByUrl("/dashboard");
				} else if (user.permissions.includes("can_list_subscriptions")) {
					this.router.navigateByUrl("/subscriptions");
				} else if (user.permissions.includes("can_view_boq_report")) {
					this.router.navigateByUrl("/reports/sub-billOfQty");
				}
			})
		);
	}
	ngOnDestroy(): void {
		this.subscriptions.forEach((sub) => sub.unsubscribe());
	}
}
