import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";

import { UnderMaintenancePageRoutingModule } from "./under-maintenance-page-routing.module";
import { UnderMaintenancePageComponent } from "./under-maintenance-page/under-maintenance-page.component";

@NgModule({
	declarations: [UnderMaintenancePageComponent],
	imports: [CommonModule, UnderMaintenancePageRoutingModule],
	exports: [UnderMaintenancePageComponent],
})
export class UnderMaintenancePageModule {}
