import { Component, Inject, OnInit } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";

export interface IConfirmDialogData {
	title: string;
	message: string;
	confirmMessage: string;
	cancelMessage: string;
}

@Component({
	selector: "app-confirm-action-dialog",
	templateUrl: "./confirm-action-dialog.component.html",
	styleUrls: ["./confirm-action-dialog.component.scss"],
})
export class ConfirmActionDialogComponent implements OnInit {
	constructor(
		public dialogRef: MatDialogRef<IConfirmDialogData>,
		@Inject(MAT_DIALOG_DATA) public data: IConfirmDialogData
	) {}

	ngOnInit(): void {
		if (!this.data.title) {
			this.data.title = "confirmation";
		}
	}

	get confirmMessage() {
		return this.data.confirmMessage || "confirm";
	}
	get cancelMessage() {
		return this.data.cancelMessage || "cancel";
	}

	confirm() {
		this.dialogRef.close(true);
	}

	cancel() {
		this.dialogRef.close(false);
	}
}
