import { Injectable, OnDestroy } from "@angular/core";
import { IAppConfig } from "../../models/classes.model";
import { YumealzApiService } from "../yumealz-api-service/yumealz-api.service";
import { Observable, Subject, Subscription, of, switchMap, take } from "rxjs";
import { AppService } from "../app-service/app-service";

@Injectable({
	providedIn: "root",
})
export class ConfigService implements OnDestroy {
	private currentRestaurantId: number | undefined;
	private currentRestaurantConfig: IAppConfig | undefined;
	private restaurantIsReady$ = new Subject<void>();
	private rxjsSubscriptions: Subscription[] = [];

	constructor(
		private yumealzApiService: YumealzApiService,
		private appService: AppService
	) {}
	ngOnDestroy(): void {
		this.rxjsSubscriptions.forEach((sub) => sub.unsubscribe());
	}

	private refreshAppConfig(newRestaurantId: number) {
		this.currentRestaurantId = newRestaurantId;
		if (newRestaurantId === undefined) return;
		this.rxjsSubscriptions.push(
			this.yumealzApiService
				.getAppConfigs(this.currentRestaurantId)
				.subscribe((response) => {
					if (response.results.length == 0) {
						this.currentRestaurantConfig = undefined;
						const errorMsg = this.appService.translate(
							"no-config-found-for-this-restaurant"
						);
						this.appService.notifyFailure(errorMsg);
						return;
					} else {
						this.currentRestaurantConfig = response.results[0];
						this.restaurantIsReady$.next();
					}
				})
		);
	}

	private getMiscObject(restaurantId: number): Observable<string | undefined> {
		if (this.currentRestaurantId != restaurantId) {
			this.refreshAppConfig(restaurantId);
			return this.restaurantIsReady$.pipe(
				take(1),
				switchMap(() => of(this.currentRestaurantConfig?.misc))
			);
		} else {
			if (this.currentRestaurantConfig) {
				return of(this.currentRestaurantConfig.misc);
			} else {
				return this.restaurantIsReady$.pipe(
					take(1),
					switchMap(() => of(this.currentRestaurantConfig?.misc))
				);
			}
		}
	}

	private transformValues(obj: any): any {
		if (typeof obj !== "object" || obj === null) return obj;

		for (let key in obj) {
			if (obj.hasOwnProperty(key)) {
				// the line below is converting all values to string (even null) removing this doesn't change anything in the final result
				// obj[key] = obj[key] + "";
				if (typeof obj[key] === "string") {
					// if it is a number
					if (/^\d+$/.test(obj[key])) {
						obj[key] = +obj[key];
					} else if (obj[key] === "true" || obj[key] === "false") {
						obj[key] = obj[key] === "true";
					} else if (obj[key] === "object") {
						obj[key] = this.transformValues(obj[key]);
					}
				}
			}
		}
		return obj;
	}

	getAppConfig(restaurantId: number): Observable<IAppConfig | undefined> {
		return this.yumealzApiService.getAppConfigs(restaurantId).pipe(
			switchMap((response) => {
				if (response.results.length == 0) {
					const errorMsg = this.appService.translate(
						"no-config-found-for-this-restaurant"
					);
					this.appService.notifyFailure(errorMsg);
					return of(undefined);
				} else {
					return of(response.results[0]);
				}
			})
		);
	}
	getMiscObjectAsJson(restaurantId: number): Observable<any | undefined> {
		return this.getMiscObject(restaurantId).pipe(
			switchMap((misc) => {
				if (!misc) return of(undefined);
				const parsedMisc = JSON.parse(misc);
				return of(this.transformValues(parsedMisc));
			})
		);
	}
	getMiscValue(
		restaurantId: number,
		key: string,
		isPortalMiscValue = true
	): Promise<any> {
		// if key includes space " " - remove it
		if (key.includes(" ")) {
			key.replace(/\ /g, "_");
		}
		if (isPortalMiscValue) key = "dashboard_" + key;

		return new Promise((resolve, reject) => {
			this.getMiscObjectAsJson(restaurantId)
				.pipe(take(1))
				.subscribe((misc) => {
					if (!misc) {
						const errorMsg = this.appService.translate(
							"no-misc-found-for-this-restaurant"
						);
						this.appService.notifyFailure(errorMsg);
						resolve(false);
					} else if (!misc.hasOwnProperty(key)) {
						resolve(false);
					} else {
						resolve(misc[key]);
					}
				});
		});
	}
	updateMiscValue(restaurantId: number, key: string, newValue: any) {
		this.getAppConfig(restaurantId)
			.pipe(take(1))
			.subscribe((config) => {
				if (!config) {
					console.error(
						"This restaurant has no config where he must have one!"
					);
				} else {
					let miscValues = JSON.parse(config.misc);
					miscValues[key] = newValue;

					config.misc = JSON.stringify(miscValues);

					this.yumealzApiService
						.updateAppConfig(config)
						.pipe(take(1))
						.subscribe((res) => {
							this.currentRestaurantConfig = res;
							this.restaurantIsReady$.next();
						});
				}
			});
	}
}
