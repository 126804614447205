import { Component, HostListener, OnInit, Renderer2 } from "@angular/core";

import { AppService } from "../../services/app-service/app-service";
import { ConfigService } from "../../services/config-service/config-service.service";
import { take } from "rxjs";
import { Router } from "@angular/router";

@Component({
	selector: "app-root",
	templateUrl: "./app.component.html",
	styleUrls: ["./app.component.scss"],
})
export class AppComponent implements OnInit {
	title = "yumealz Dashboard";
	constructor(
		private renderer: Renderer2,
		private appService: AppService,
		private configService: ConfigService,
		private router: Router
	) {}
	ngOnInit(): void {
		if (this.appService.isProduction()) this.addHotjarScript();

		this.navigateToOnboardingIfRequired();
	}

	async navigateToOnboardingIfRequired() {
		const needsOnboardingNameInLocalStorage = "needs_onboarding";

		const needsOnboarding: null | string = localStorage.getItem(
			needsOnboardingNameInLocalStorage
		);
		if (needsOnboarding && needsOnboarding === "false") {
			return;
		}

		const restaurants = await this.appService.getListOfRestaurantsPromise();
		// todo update this when supporting onboarding for multi restaurants
		if (restaurants.length === 1) {
			const needsOnboarding = await this.configService.getMiscValue(
				restaurants[0].id,
				"needs_onboarding"
			);

			if (!needsOnboarding) {
				localStorage.setItem(needsOnboardingNameInLocalStorage, false + "");
			} else {
				this.router.navigate(["/onboarding"]);
			}
		}
	}
	addHotjarScript() {
		const s = this.renderer.createElement("script");
		s.text = `(function(h,o,t,j,a,r){
                h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};
                h._hjSettings={hjid:3867212,hjsv:6};
                a=o.getElementsByTagName('head')[0];
                r=o.createElement('script');r.async=1;
                r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
                a.appendChild(r);
              })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');`;
		this.renderer.appendChild(document.head, s);
	}
	@HostListener("document:click", ["$event"])
	onGlobalClick(event: MouseEvent): void {
		this.appService.handleGlobalClick(event);
	}
}
